<template>
	<div class="disclaimer">
		<div class="calendar__disclaimer">
			{{ disclaimerContent }}
			<button
				v-if="modalId"
				class="calendar__disclaimer-button"
				@click="openModal"
			>
				<svg-icon :icon="iconDisclaimer" />
			</button>
		</div>
		<default-modal v-if="modalId" :modal-id="modalId">
			<template slot="modal-container">
				<p class="calendar__modal-title">{{ modalTitle }}</p>
				<p class="calendar__modal-content">{{ modalContent }}</p>
			</template>
		</default-modal>
	</div>
</template>
<script>
import { EventBus } from '../../utils/EventBus';
import DefaultModal from '../DefaultModal/DefaultModal';
import SvgIcon from '../SvgIcon/SvgIcon';

export default {
	name: 'CalendarDisclaimer',
	components: {
		DefaultModal,
		SvgIcon,
	},
	props: {
		iconDisclaimer: { type: String, required: false, default: 'info' },
		disclaimerContent: { type: String, required: true },
		modalId: { type: String, required: false, default: null },
		modalContent: { type: String, required: false, default: '' },
		modalTitle: { type: String, required: false, default: '' },
	},
	methods: {
		openModal: function () {
			EventBus.$emit('open-modal', this.modalId);
		},
	},
};
</script>
