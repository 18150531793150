<template>
	<div v-if="tickets" class="calendar__body-item-wrapper">
		<div class="calendar__body-item-prices">
			{{ priceRange }}
		</div>
		<div class="calendar__body-item-tickets">
			<div
				v-for="ticket in ticketsList"
				:key="ticket.start"
				class="calendar__body-item-ticket"
			>
				<a
					v-if="!ticket.isSoldOut"
					:href="ticket.url"
					class="cta-btn calendar__body-item-schedule"
					target="_blank"
					:aria-label="ticket.ariaLabel"
				>
					{{ ticket.showHour }}
				</a>
				<span
					v-if="ticket.isSoldOut"
					class="cta-btn calendar__body-item-schedule -soldout"
				>
					{{ ticket.showHour }}
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import { format, getMinutes, getHours, parseISO } from 'date-fns';

export default {
	name: 'CalendarItem',
	props: {
		tickets: { type: Array, required: true },
	},
	computed: {
		ticketsList: function () {
			// Add propreties to the ticket in the Array (Show Hour and Aria Label text)
			return this.tickets.map((ticket) => {
				const startDate = parseISO(ticket.start);
				const hours = getHours(startDate);
				const minutes = getMinutes(startDate);

				let showHour = hours - 12;
				showHour += minutes > 0 ? ':' + minutes : '';
				showHour += hours >= 12 ? 'PM' : 'AM';

				const ariaLabel = `${showHour}, ${format(
					startDate,
					'MMMM dd yyyy'
				)}, Buy tickets`;

				return {
					...ticket,
					...{ showHour, ariaLabel },
				};
			});
		},
		priceRange: function () {
			let min, max;

			for (let i = 0; i < this.tickets.length; i++) {
				const ticketPriceRange = this.tickets[i].price_range;
				if (!min || ticketPriceRange.min_price < min) {
					min = ticketPriceRange.min_price;
				}
				if (!max || ticketPriceRange.max_price > max) {
					max = ticketPriceRange.max_price;
				}
			}
			return `$${min} - ${max}`;
		},
	},
};
</script>
