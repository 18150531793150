import Vue from 'vue';

// Vue Config
Vue.config.productionTip = false;
Vue.config.devtools = true;

// ----- Plugins -------
// Customs
import UniqueIdPlugin from '../../plugins/UniqueIdPlugin.js';
import WindowWidthPlugin from '../../plugins/WindowWidthPlugin.js';

// Mixins
import { KeySiteCore } from '../../mixins/KeySiteCore';
import ResponsiveBackgroundImageMixin from '../../mixins/ResponsiveBackgroundImageMixin.js';

// Components
import CalendarShow from '@c/CalendarShow';
import DefaultAccordionItem from '@c/DefaultAccordionItem';
import DefaultButton from '@c/DefaultButton';
import DefaultHero from '@c/DefaultHero';
import DefaultPicture from '@c/DefaultPicture';
import DefaultSection from '@c/DefaultSection';
import SplitBlock from '@c/SplitBlock';
import VideoYoutubePlayer from '@c/VideoYoutubePlayer';

// Store
import store from '../../stores/storeBMG';

// CSS
import './styles/main.scss';

Vue.use(KeySiteCore);
Vue.use(UniqueIdPlugin);

new Vue({
	el: '#site',
	store,
	components: {
		CalendarShow,
		DefaultAccordionItem,
		DefaultButton,
		DefaultHero,
		DefaultPicture,
		DefaultSection,
		SplitBlock,
		VideoYoutubePlayer,
	},
	mixins: [ResponsiveBackgroundImageMixin],
	created() {
		Vue.use(WindowWidthPlugin, this.$store); // need the store instance in the plugin
	},
});
