import Vue from 'vue';
import Vuex from 'vuex';

import WindowWidth from './modules/WindowWidth.js';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		WindowWidth,
	},

	state: {
		dateContext: new Date(),
	},

	mutations: {
		SET_DATE_CONTEXT(state, newDateContext) {
			state.dateContext = newDateContext;
		},
	},

	actions: {},
});
