<template>
	<div class="calendar__container">
		<div
			v-if="ticketsData && ticketsData.length > 0"
			class="calendar"
			:class="{ 'calendar--mobile': isMobile }"
		>
			<calendar-header
				arrow="arrow-calendar"
				:last-ticket-date="lastTicketDate"
				select-placeholder="Choose a month"
			></calendar-header>
			<calendar-body
				:is-mobile="isMobile"
				:available-dates="availableDates"
			></calendar-body>
		</div>
		<div
			v-if="!ticketsData || ticketsData.length <= 0"
			class="calendar calendar--no-show"
		>
			<p>No show currently</p>
		</div>
		<calendar-disclaimer
			:icon-disclaimer="iconDisclaimer"
			:disclaimer-content="disclaimerContent"
			:modal-id="modalId"
			:modal-title="modalTitle"
			:modal-content="modalContent"
		></calendar-disclaimer>
	</div>
</template>

<script>
import CalendarHeader from './CalendarHeader';
import CalendarBody from './CalendarBody';
import CalendarDisclaimer from './CalendarDisclaimer';

export default {
	name: 'CalendarShow',
	components: {
		CalendarHeader,
		CalendarBody,
		CalendarDisclaimer,
	},
	props: {
		ticketsData: { type: Array, required: true },
		iconDisclaimer: { type: String, required: false, default: 'info' },
		disclaimerContent: { type: String, required: true },
		modalId: { type: String, required: false, default: null },
		modalContent: { type: String, required: false, default: '' },
		modalTitle: { type: String, required: false, default: '' },
	},
	computed: {
		lastTicketDate: function () {
			return this.ticketsData[this.ticketsData.length - 1].start || null;
		},
		availableDates: function () {
			const dates = {};

			for (let i = 0; i < this.ticketsData.length; i++) {
				const date = this.ticketsData[i].start.substring(0, 10);
				if (!dates[date]) {
					dates[date] = {
						date: date,
						tickets: [this.ticketsData[i]],
					};
				} else {
					dates[date].tickets.push(this.ticketsData[i]);
				}
			}

			return dates;
		},
		isMobile: function () {
			return (
				this.$store.state.WindowWidth.name === 'mobile' ||
				this.$store.state.WindowWidth.name === 'tablet'
			);
		},
	},
};
</script>
