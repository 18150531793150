<template>
	<div class="calendar__header">
		<button
			class="previous-button"
			:class="{ hidden: !hasPreviousMonth }"
			:aria-label="`Previous month, ${previousMonth}`"
			@click="setToPreviousMonth"
		>
			<svg-icon :icon="arrow" />
		</button>
		<div class="dropdown__container">
			<span class="sr-only">
				Choose another month for available performances, currently displaying
				{{ selectedMonth }}
			</span>
			<select v-model="selectedMonth" class="dropdown">
				<option disabled>{{ selectPlaceholder }}</option>
				<option v-for="item in dropdownMonths" :key="item.text" :value="item">
					{{ item.text }}
				</option>
			</select>
		</div>
		<button
			class="next-button"
			:class="{ hidden: !hasNextMonth }"
			:aria-label="`Next month, ${nextMonth}`"
			@click="setToNextMonth"
		>
			<svg-icon :icon="arrow" />
		</button>
	</div>
</template>

<script>
import {
	format,
	startOfMonth,
	endOfMonth,
	addMonths,
	subMonths,
	parseISO,
	setMonth,
	setYear,
	compareAsc,
	differenceInCalendarMonths,
} from 'date-fns';

import SvgIcon from '../SvgIcon/SvgIcon';

export default {
	name: 'CalendarHeader',
	components: {
		SvgIcon,
	},
	props: {
		arrow: { type: String, required: true },
		lastTicketDate: { type: String, required: true },
		selectPlaceholder: {
			type: String,
			required: false,
			default: 'Choose a month',
		},
	},
	data() {
		return {
			dropdownMonths: {},
		};
	},
	computed: {
		previousMonth: function () {
			return format(subMonths(this.dateContext, 1), 'MMMM yyyy');
		},
		nextMonth: function () {
			return format(addMonths(this.dateContext, 1), 'MMMM yyyy');
		},
		hasPreviousMonth: function () {
			return !(compareAsc(this.dateContext, new Date()) < 1);
		},
		hasNextMonth: function () {
			return !(
				compareAsc(
					this.dateContext,
					startOfMonth(parseISO(this.lastTicketDate))
				) > -1
			);
		},
		selectedMonth: {
			get: function () {
				const monthIndex = format(this.dateContext, 'YMM');
				return this.dropdownMonths[monthIndex];
			},
			set: function (dropdownValue) {
				let newDateContext = setMonth(
					this.$store.state.dateContext,
					parseInt(dropdownValue.month) - 1
				);
				newDateContext = setYear(newDateContext, parseInt(dropdownValue.year));

				this.$store.commit('SET_DATE_CONTEXT', newDateContext);
			},
		},
		dateContext: function () {
			return this.$store.state.dateContext;
		},
	},
	created() {
		this.setDropdownMonths(new Date(), this.lastTicketDate);
	},
	methods: {
		setToNextMonth: function () {
			const newDateContext = addMonths(this.$store.state.dateContext, 1);
			this.$store.commit('SET_DATE_CONTEXT', newDateContext);
		},

		setToPreviousMonth: function () {
			const newDateContext = subMonths(this.$store.state.dateContext, 1);
			this.$store.commit('SET_DATE_CONTEXT', newDateContext);
		},

		setDropdownMonths: function (fromDate, toDate) {
			let selectDate = fromDate;

			const numberOfMonths =
				differenceInCalendarMonths(
					endOfMonth(parseISO(toDate)),
					startOfMonth(selectDate)
				) + 1;

			for (let i = 0; i < numberOfMonths; i++) {
				const year = format(selectDate, 'Y');
				const month = format(selectDate, 'MM');
				const monthName = format(selectDate, 'MMMM');

				this.dropdownMonths[`${year}${month}`] = {
					text: `${monthName} ${year}`,
					month: month,
					year: year,
				};
				selectDate = addMonths(selectDate, 1);
			}
		},
	},
};
</script>
