<template>
	<div class="calendar__body">
		<template v-if="!isMobile">
			<div
				v-for="(blank, indexfirstDayOfMonth) in firstDayOfMonth"
				:key="`firstDay ${indexfirstDayOfMonth}`"
				class="calendar__body-item"
			></div>
		</template>
		<template v-for="date in daysInMonth">
			<div
				v-if="!isMobile || availableDates[date.dateString]"
				:key="date.index"
				class="calendar__body-item"
			>
				<div
					ref="days"
					class="calendar__body-item-content"
					:class="{
						'calendar__body-item-content--has-event':
							availableDates[date.dateString],
					}"
				>
					<div class="calendar__body-item-date">
						<div class="calendar__body-item-number">
							{{ date.index }}
						</div>
						<div class="calendar__body-item-day">
							{{ date.dayName }}
						</div>
					</div>
					<calendar-item
						v-if="availableDates[date.dateString]"
						:tickets="availableDates[date.dateString].tickets"
					></calendar-item>
				</div>
			</div>
		</template>

		<template v-if="!isMobile">
			<div
				v-for="(blankItem, indexLastBlanks) in numberOfBlanksAtEndOfTheMonth"
				:key="`lastDays ${indexLastBlanks}`"
				class="calendar__body-last-item"
			></div>
		</template>
	</div>
</template>

<script>
import CalendarItem from './CalendarItem';

import {
	format,
	getDaysInMonth,
	getDay,
	startOfMonth,
	endOfMonth,
	parseISO,
} from 'date-fns';

export default {
	name: 'CalendarBody',
	components: {
		CalendarItem,
	},
	props: {
		availableDates: { type: Object, required: true },
		isMobile: { type: Boolean, required: false, default: false },
	},
	computed: {
		dateContext: function () {
			return this.$store.state.dateContext;
		},
		daysInMonth: function () {
			const tmpDates = [];
			const numberOfDays = getDaysInMonth(this.dateContext);
			for (let i = 1; i <= numberOfDays; i++) {
				const dateString = `${format(this.dateContext, 'yyyy-MM')}-${
					i.toString().length > 1 ? i : '0' + i
				}`;

				tmpDates.push({
					index: i,
					dayName: format(parseISO(dateString), 'E'),
					dateString: dateString,
				});
			}
			return tmpDates;
		},
		firstDayOfMonth: function () {
			return getDay(startOfMonth(this.dateContext));
		},
		lastDayOfMonth: function () {
			return getDay(endOfMonth(this.dateContext));
		},
		numberOfBlanksAtEndOfTheMonth: function () {
			return 6 - this.lastDayOfMonth;
		},
	},
};
</script>
